import {predicate} from '@prismicio/client';
import {createError} from '#app';
import {useSpanWrap, useLogger} from '#imports';
import usePrismicClient from './usePrismicClient';

export function usePrismicRedirect(path: string, lang: string) {
    const logger = useLogger();
    const client = usePrismicClient();
    const predicates = [predicate.at('my.redirect.redirectFrom.url', path)];

    const denyList = ['/_nuxt', '/vendor/', '/~partytown', '/notifications-worker.js'];

    const fetchRedirect = async () => {
        if (denyList.some((item) => path?.startsWith(item))) {
            throw createError({message: `Will not fetch Prismic redirect for ${path}`, statusCode: 404});
        }

        try {
            return await useSpanWrap(() => client!.getFirst({predicates, lang}), {
                httpMethod: 'prismic.get',
                kind: 'prismic',
                name: `[prismic] ${path}`,
            });
        } catch (error) {
            logger.error(`Prismic redirect not found for ${path} in ${lang}`, error);
            throw createError({message: `Prismic redirect not found for ${path} in ${lang}`, statusCode: 404});
        }
    };

    return {
        fetchRedirect,
    };
}
